import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { globalHistory } from '@reach/router';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import { useMenuToggle } from '@components/Navigation/Navigation.Hooks';

import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Anchor from '@components/Anchor';
import Link from '@components/Link';
import SocialLinks from '@components/SocialLinks/SocialLinks.Basic';

// import BackgroundSVG from '@images/hero-background.svg';
// import BackgroundSVG from '@misc/hero-background.svg';
import HeroBackground from '@components/Hero/Hero.Background';

interface MobileHeaderProps {
    toggled: boolean;
    animated: boolean;
    toggleMenu: (value?: boolean) => void;
}

const NavigationHeaderMobile: React.FC<MobileHeaderProps> = ({
    toggled = false,
    animated = false,
    toggleMenu = () => {}
}) => {
    const intl = useIntl();
    const { location, navigate } = globalHistory;

    // To Check whether a MobileMenuItem is Active
    const isActive = (path) => location.pathname.includes(path);

    return (
        <MobileNavContainer active={ toggled } animation={ animated }>
            {/*<Background />*/}
            <MobilePopup>
                <MobileNav>

                    <MobileMenuContainer animation={ animated }>
                        <MobileMenu active={ toggled }>
                            <MobileMenuItem active={ toggled } animation={ animated }>
                                <Link to="/#services" onClick={ toggleMenu } data-scroll>
                                    <MenuLinkParagraph>{intl.formatMessage({ id: 'services-link' })}</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            <MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('web-development-services') }>
                                <Link to="/web-development-services/">
                                    <MenuLinkParagraph>{intl.formatMessage({ id: 'web-link' })}</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            <MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('online-marketing') }>
                                <Link to="/online-marketing/">
                                    <MenuLinkParagraph>{intl.formatMessage({ id: 'marketing-link' })}</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            <MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('seo-services') }>
                                <Link to="/seo-services/">
                                    <MenuLinkParagraph>{intl.formatMessage({ id: 'seo-link' })}</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            {/*<MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('app-development') }>
                                <Link to="/app-development/">
                                    <MenuLinkParagraph>{intl.formatMessage({ id: 'app-link' })}</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>*/}

                            {/*<MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('about') }>
                                <Link to="/about/">
                                    <MenuLinkParagraph>{intl.formatMessage({ id: 'about-link' })}</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>*/}

                            <MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('contact') }>
                                <Link to="/contact/">
                                    <MenuLinkParagraph>{intl.formatMessage({ id: 'contact-link' })}</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>
                        </MobileMenu>
                    </MobileMenuContainer>

                    <MobileContactContainer active={ toggled } animation={ animated }>
                        <ContactAnchor href="mailto:hello@dlmrconsulting.com" className="button button--navigation">hello@dlmrconsulting.com</ContactAnchor>
                        <SocialLinks />
                    </MobileContactContainer>

                </MobileNav>
            </MobilePopup>
        </MobileNavContainer>
    );
}


const Background = styled.div`
    position: absolute;
    // width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    background: ${p => p.theme.colors.background};
    opacity: .90;
    backdrop-filter: blur(4px);
`

/**
 * Thanks to Daniel Korpai
 * Menu Inspiration from http://danielkorpai.com/
*/
const MobileNavContainer = styled.div<{ active?: boolean, animation?: boolean }>`
    position: fixed;
    width: 100%;
    height: 0px;
    top: 0;
    left: 0;
    background: ${p => p.theme.colors.background};
    z-index: 40;
    display: block;
    transition: .56s cubic-bezier(0.52, 0.16, 0.24, 1);
    opacity: 1;
    pointer-events: none;
    overflow-x: hidden;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    ${p => p.active && `
        opacity: 1;
        cursor: pointer;
        pointer-events: auto;
        height: 100%;
    `};

    ${p => p.animation && `
        transition-delay: 550ms;
    `};
`;

const MobilePopup = styled.div`
    background-color: transparent;
    position: absolute;
    z-index: 12;
    opacity: 1;
    pointer-events: auto;
    height: 100%;

    // was tablet_ipad
    ${mediaqueries.from_laptop`
         display: none;
         visibility: hidden;
         opacity: 0;
    `};
`;

const MobileNav = styled.div`
    padding-left: 4%;
    padding-right: 4%;
    top: 50px;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const MobileMenuContainer = styled.div<{ animation?: boolean }>`
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
`;

const MobileMenu = styled.ul<{ active?: boolean }>`
    margin: 0;
`;

const MobileMenuItem = styled.li<{ active?: boolean, animation?: boolean, selected?: boolean }>`
    margin-bottom: 10px;

    ${mediaqueries.from_mobile_i6`
        margin-bottom: 15px;
    `};

    ${mediaqueries.from_mobile_i6p`
        margin-bottom: 20px;
    `};

    &:last-of-type {
        margin-bottom: 0;
    }

    ${p => p.active ? `
        // Reverse Animation/State
        transform: none;
        opacity: 1;
    `: `
        // Normal Animation/State
        opacity: 0;
        transform: translateY(-30px);
    `};

    ${p => p.selected && `
        & a, p {
            color: ${ p.theme.colors.brand };
        }
    `};

    ${p => p.animation ? `
        // ==========================================================
        // Reverse Animation/State
        // ==========================================================
        transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:nth-of-type(1) {
            transition-delay: 500ms;
        }
        &:nth-of-type(2) {
            transition-delay: 475ms;
        }
        &:nth-of-type(3) {
            transition-delay: 450ms;
        }
        &:nth-of-type(4) {
            transition-delay: 425ms;
        }
        &:nth-of-type(5) {
            transition-delay: 400ms;
        }
        &:nth-of-type(6) {
            transition-delay: 375ms;
        }
        &:nth-of-type(7) {
            transition-delay: 350ms;
        }
    ` : `
        // ==========================================================
        // Normal Animation/State
        // ==========================================================
        transition: all 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:nth-of-type(1) {
            transition-delay: 400ms;
        }
        &:nth-of-type(2) {
            transition-delay: 450ms;
        }
        &:nth-of-type(3) {
            transition-delay: 500ms;
        }
        &:nth-of-type(4) {
            transition-delay: 550ms;
        }
        &:nth-of-type(5) {
            transition-delay: 600ms;
        }
        &:nth-of-type(6) {
            transition-delay: 650ms;
        }
        &:nth-of-type(7) {
            transition-delay: 700ms;
        }
    `};
`;

const MenuLinkParagraph = styled(Paragraph)`
    font-weight: ${p => p.theme.fontWeights.bold};
    font-size: 22px;
    margin-bottom: 0;
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 8px;

    ${mediaqueries.from_mobile_i6`
        font-size: 28px;
        padding-top: 10px;
        padding-bottom: 10px;
    `};

    ${mediaqueries.from_mobile_i6p`
        font-size: 32px;
    `};
`;

const MobileContactContainer = styled.div<{ active?: boolean, animation?: boolean }>`
    transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    

    ${mediaqueries.from_mobile_i6`
        margin-bottom: 15px;
    `};

    ${p => p.active ? `
        // Reverse Animation/State
        opacity: 1;
        transform: none;
    `: `
        // Normal Animation/State
        opacity: 0;
        transform: translateY(-15px);
    `};

    ${p => p.animation ? `
        // Reverse Animation/State
        transition-delay: 50ms;
    ` : `
        // Normal Animation/State
        transition-delay: 700ms;
    `};

    
`;

const ContactAnchor = styled(Anchor)`
    width: 100% !important;
    color: white;
    margin-bottom: 10px;
    padding-top: 12px;
    padding-bottom: 12px;

    ${mediaqueries.from_mobile_i6`
        margin-bottom: 15px;
        padding-top: 14px;
        padding-bottom: 14px;
    `};
`;

// .social-links__flex {
//     margin-left: 0;
// }

// .social-links__item {
//     opacity: .3;
//     text-align: center;
//     margin-left: 5px;
//     margin-right: 5px;
// }

// .social-links__icon {
//     svg {
//         width: 18px;
//     }
// }

// .social-icon-color {
//     fill: $black;
// }

export default NavigationHeaderMobile;
