import React from 'react';
import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Anchor from '@components/Anchor';

interface SocialLinksProps {
    links: {
        name: string;
        url: string;
    }[];
    fill: string;
};

const icons = {
    twitter: 'twitter.svg',
    facebook: 'facebook.svg',
    instagram: 'instagram.svg',
    medium: 'medium.svg',
    dribbble: 'dribbble.svg',
    github: 'github.svg',
    linkedin: 'linkedin.svg',
};

const getHostname = url => {
    return new URL(url.toLowerCase()).hostname.replace('www.', '').split('.')[0];
};

const SocialLinksBasic: React.FC<SocialLinksProps> = ({
    links,
    fill = '#73737D'
}) => {
    if (!links) return null;

    return (
        <>
        <ul>
            {links.map(option => {
                const name = option.name || getHostname(option.url);
                const Icon = require('@old-icons/' + icons[name]);
                const StyledIcon = styled(Icon)`
                    & * {
                        fill: ${fill}    
                    }
                `;

                if (!Icon) {
                    throw new Error(
                        `unsupported social link name=${name} / url=${option.url}`,
                    );
                }
                return (
                    <SocialIconContainer>
                        <StyledAnchor
                            key={option.url}
                            target="_blank"
                            rel="noopener nofollow"
                            data-a11y="false"
                            aria-label={`Link to ${option.url}`}
                            href={option.url}
                        >
                            <StyledIcon />
                            <Hidden>Link to ${option.url}</Hidden>
                        </StyledAnchor>
                    </SocialIconContainer>
                );
            })}
        </ul>
        </>
    );
};

export default SocialLinksBasic;


const SocialIconContainer = styled.li`
    display: inline-block;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    opacity: .5;
    transition: .3s;

    ${mediaqueries.from_tablet_ipad`
        margin-left: 15px;
        margin-right: 15px;
    `}

    &:hover {
        opacity: 1;
    }

    // &:hover {
    //     svg {
    //         &:hover * {
    //             fill: ${p => p.theme.colors.brand};
    //         }
    //         * {
    //             transition: fill 0.25s var(--ease-in-out-quad);
    //         }
    //     }
    // }

    // ==========================================================
    // a11y support
    // ==========================================================
    &[data-a11y='true']:focus::after {
        content: '';
        position: absolute;
        left: -50%;
        top: -20%;
        width: 200%;
        height: 160%;
        border: 2px solid ${p => p.theme.colors.accent};
        background: rgba(255, 255, 255, 0.01);
        border-radius: 5px;
    }
`;

const StyledAnchor = styled(Anchor)`
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    margin-left: -8px;
    padding: 8px 8px;
    border-radius: 6px;
    // -webkit-transition: ease .3s all;
    // transition: ease .3s all;
`;

const Hidden = styled.span`
    width: 0px;
    height: 0px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    display: inline-block;
`;
