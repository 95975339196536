import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Paragraph = styled.p<{ subtitle?: boolean, center?: boolean, lead?: boolean }>`
    // font-family: ${p => p.theme.fonts.sansSerif};
    font-size: ${p => p.theme.fontSizes.base};
    font-weight: ${p => p.theme.fontWeights.regular};
    line-height: ${p => p.theme.lineHeights.regular};
    color: ${p => p.theme.colors.articleText};
    transition: ${p => p.theme.colorModeTransition};
    margin-bottom: 20px;
    overflow-wrap: break-word;

    width: 100%;
    max-width: 680px;

    ${mediaqueries.from_tablet`
        font-size: ${p => p.theme.fontSizes.big};
    `};

    ${mediaqueries.from_tablet_ipad`
        margin-bottom: 30px;
    `};

    & b, & strong {
        font-weight: ${p => p.theme.fontWeights.bold};
        display: inline;
        color: inherit;
        font-size: inherit;
    }

    // ==========================================================
    // Center
    // ==========================================================
    ${p => p.center && `text-align: center;`};

    // ==========================================================
    // Subtitle
    // ==========================================================
    ${p => p.subtitle && `
        font-size: calc(${p.theme.fontSizes.base}*1.125);
        line-height: 1.5;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        // font-weight: 600;
    `};

    ${p => p.subtitle && mediaqueries.from_mobile_i6`
        font-size: calc(${p.theme.fontSizes.base}*1.25);
    `};

    ${p => p.subtitle && mediaqueries.from_tablet`
        font-size: calc(${p.theme.fontSizes.big}*1.25);
    `};

    ${p => p.subtitle && mediaqueries.from_tablet_ipad`
        font-size: calc(${p.theme.fontSizes.big}*1.333);
        margin-bottom: 30px;
        max-width: 500px;
    `};

    ${p => p.subtitle && mediaqueries.from_laptop`
        font-size: calc(${p.theme.fontSizes.big}*1.245);
        max-width: 767px;
    `};

    ${p => p.subtitle && mediaqueries.from_desktop`
        font-size: calc(${p.theme.fontSizes.big}*1.333);
    `};

    // ==========================================================
    // Content Lead
    // ==========================================================
    ${p => p.lead && `
        font-size: calc(${p.theme.fontSizes.base}*1);
        // font-weight: $font-weight__regular;
        // color: $headerColor;
        // line-height: $line-height__regular;
    `};
    
    ${p => p.lead && mediaqueries.from_mobile_i6`
        font-size: calc(${p.theme.fontSizes.base}*1.125);
    `};

    ${p => p.lead && mediaqueries.from_tablet`
        font-size: calc(${p.theme.fontSizes.big}*1.166);
        margin-bottom: 20px;
    `};
    
    ${p => p.lead && mediaqueries.from_tablet_ipad`
        font-size: calc(${p.theme.fontSizes.big}*1.166);
        max-width: 620px;
    `};

    ${p => p.lead && mediaqueries.from_laptop`
        max-width: 700px;
    `};

    ${p => p.lead && mediaqueries.from_desktop`
        font-size: calc(${p.theme.fontSizes.big}*1.166);
    `};
    
    ${p => p.lead && p.white && mediaqueries.from_mobile_i6`
        color: white;
    `};
`;

export default Paragraph;
