import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const HeadingsContainer = styled.div<{ center?: boolean }>`
    max-width: 550px;

    ${mediaqueries.from_tablet_ipad`
        max-width: 100%;
    `};

    ${mediaqueries.from_laptop`
        max-width: 600px;
    `};

    ${p => p.center && `text-align: center;`};
`;

export default HeadingsContainer;