import CSSFadeIn from "./Transitions.CSS.FadeIn";
import CSSFadeInUp from "./Transitions.CSS.FadeInUp";
import CSSFadeInZoom from "./Transitions.CSS.FadeInZoom";
import CSSFloat from "./Transitions.CSS.Float";

export default {
  CSS: {
    FadeIn: CSSFadeIn,
    FadeInUp: CSSFadeInUp,
    FadeInZoom: CSSFadeInZoom,
    Float: CSSFloat
  },
};
