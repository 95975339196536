import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

/**
 * This Component is the container of cards. It's the base card used for listing services
 * @example
 *     <CardDefault hover animateImage animateBorder>
 *         <CardImage>
 *             <Image />
 *             <CardBorder />
 *         </CardImage>
 *         <CardContent>
 *             <Paragraph></Paragraph>
 *         </CardContent>
 *     </CardDefault>
*/
const CardDefault = styled.div<{ hover?: boolean, animateImage?: boolean, animateBorder?: boolean, flexStartAlign?: boolean }>`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
    background-color: #FFF;
    border-radius: .25em;
    overflow: hidden;
    display: grid;

    // This Box Shadow is from novela.narrative.com
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    ${p => p.flexStartAlign && `
        align-content: flex-start;
    `};
    
    ${p => p.hover && `
        &:hover, &:focus {
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
            box-shadow: 0 1px 2px rgba(0,0,0,0.05), 0 15px 30px rgba(0,0,0,0.1);
        }
    `};

    // ==========================================================
    // Animate Image <CardImage />, <Img />
    // ==========================================================
    ${p => p.animateImage && `
        // To Animate <Img>
        &:hover img, &:focus img {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            transition transform 9s cubic-bezier(0.2, 0.8, 0.2, 1) !important;
        }

        // For the Background Gradients <CardImage>
        &:hover > div:after, &:focus > div:after {
            opacity: 1;
        }

        &:hover > div:before, &:focus > div:before {
            opacity: 0;
        }
    `};
    
    // ==========================================================
    // Animate Border <CardBorder />
    // ==========================================================
    ${p => p.animateBorder && `
        // To Animate <CardBorder />
        &:hover div.card-border, &:focus div.card-border {
            opacity: 1;
            transform: translate3d(0px, 0px, 0px);
        }
    `};
`;

export default CardDefault;
