import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

import CSSFadeInProps from "@types";

/**
 * Usage:
 * @example
 * <Transitions.CSS.FadeIn as={condition ? Link : "div"}>
 *     ...stuff
 * </Transitions.CSS.FadeIn>
*/

const CSSFadeIn: React.FC<CSSFadeInProps> = ({ as, delay, children, ...props }) => {
  return <Transition as={as} delay={delay} {...props}>{children}</Transition>;
};

export default CSSFadeIn;

export const FadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Transition = styled.div<{ delay?: number }>`
    opacity: 0;
    animation: ${FadeIn} 0.3s linear forwards;
    animation-delay: ${p => p.delay ? `${p.delay}s` : `0s`};

    // ==========================================================
    // For Chrome -- to have animation smoother
    // https://stackoverflow.com/questions/37629745/why-isnt-my-css3-animation-smooth-in-google-chrome-but-very-smooth-on-other-br
    // ==========================================================
    transform: translateZ(0);
    // backface-visibility: hidden;
    // perspective: 1000;
`;
