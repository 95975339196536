import React from "react"
import styled from "@emotion/styled"

import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 *
 * This component is a semi-dynamic solution for Image loading with variable name. It's useful because we
 * don't want to rewrite graphql queries for each time we want an Image.
 */

/**
 * To soften the blur-up we get from the default configuration of gatbsy image
 * we're adding a CSS blur to the image. This makes it smoother!
 */
const StyledGatsbyImage = styled(Img)`
  & > img {
    filter: blur(8px);
  }
`


const Image = ({ imgName, classes='', ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === imgName
      )
      if (!image) {
        return null
      }
      // console.log(image);
      return <StyledGatsbyImage fluid={image.node.fluid} className={classes} {...props} />
    }}
  />
)
export default Image
