import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

import CSSFadeInProps from "@types";

/**
 * Usage:
 * @example
 * <Transitions.CSS.Float as={condition ? Link : "div"} delay="0.2">
 *     ...stuff
 * </Transitions.CSS.Float>
*/

const CSSFloat: React.FC<CSSFadeInProps> = ({ as, delay = '0.5', stop = false, children, ...props }) => {
  return <Transition as={as} delay={delay} stop={stop} {...props}>{children}</Transition>;
};

export default CSSFloat;

export const Float = keyframes`
    // 0% {
    //     transform: translateY(10px) rotate3d(0, 0, 0, 0deg)
    // }
    // to {
    //     transform: translateY(-10px) rotate3d(0, 0, 0, 0deg)
    // }
    0% {
        transform: translateY(10px)
    }
    to {
        transform: translateY(-10px)
    }
`;

const Transition = styled.div<{ delay?: number, stop?: boolean }>`
    // opacity: 0;
    will-change: transform;
    animation-duration: 5s;
    animation-delay: ${p => p.delay && `${p.delay}s`};
    animation-name: ${Float};
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;

    ${p => p.stop && ` animation: none; `}

    // ==========================================================
    // For Chrome -- to have animation smoother
    // https://stackoverflow.com/questions/37629745/why-isnt-my-css3-animation-smooth-in-google-chrome-but-very-smooth-on-other-br
    // ==========================================================
    transform: translateZ(0);
    // backface-visibility: hidden;
    // perspective: 1000px;
`;