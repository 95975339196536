import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { globalHistory } from '@reach/router';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import { useColorMode } from 'theme-ui';
import { useMenuToggle } from '@components/Navigation/Navigation.Hooks';

import { FadeIn } from '@components/Transitions/Transitions.CSS.FadeIn';
import Link from '@components/Link';
import Anchor from '@components/Anchor';
import Button from '@components/Button';
import Headings from '@components/Headings';
import NavigationHeaderMobile from '@components/Navigation/Navigation.Header.Mobile';

// import Icons from '@icons';
// import Link as IconLink from '@icons/ui/Link.Icon';
// import ChevronLeft as IconChevronLeft from '@icons/ui/ChevronLeft.Icon';
// import Ex as IconEx from '@icons/ui/Ex.Icon';

import {
  copyToClipboard,
  getWindowDimensions,
  getBreakpointFromTheme,
  useScrollPosition,
} from '@utils';

const siteQuery = graphql`
    {
        site {
            siteMetadata {
                basePath
            }
        }
    }

`;

const DarkModeToggle: React.FC<{ inverted: boolean }> = ({ inverted = false }) => {
    const [colorMode, setColorMode] = useColorMode();
    const isDark = colorMode === `dark`;

    function toggleColorMode(event) {
        event.preventDefault();
        setColorMode(isDark ? `light` : `dark`);
    }

    // ==========================================================
    // Added Code
    // ==========================================================

    return (
    <IconWrapper
        isDark={isDark}
        onClick={toggleColorMode}
        data-a11y="false"
        aria-label={isDark ? "Activate light mode" : "Activate dark mode"}
        title={isDark ? "Activate light mode" : "Activate dark mode"}
    >
        <MoonOrSun isDark={isDark} />
        <MoonMask isDark={isDark} inverted={ inverted } />
    </IconWrapper>
    );
};

// const SharePageButton: React.FC<{}> = () => {
//     const [hasCopied, setHasCopied] = useState<boolean>(false);
//     const [colorMode] = useColorMode();
//     const isDark = colorMode === `dark`;
//     const fill = isDark ? "#fff" : "#000";

//     function copyToClipboardOnClick() {
//         if (hasCopied) return;

//         copyToClipboard(window.location.href);
//         setHasCopied(true);

//         setTimeout(() => {
//             setHasCopied(false);
//         }, 1000);
//     }

//     return (
//         <IconWrapper
//           isDark={isDark}
//           onClick={copyToClipboardOnClick}
//           data-a11y="false"
//           aria-label="Copy URL to clipboard"
//           title="Copy URL to clipboard"
//         >
//             <IconLink fill={fill} />
//             <ToolTip isDark={isDark} hasCopied={hasCopied}>
//                 Copied
//             </ToolTip>
//         </IconWrapper>
//     );
// };

const NavigationHeader: React.FC<{ toggleScroll: boolean }> = ({ toggleScroll = true }) => {
    const [showBackArrow, setShowBackArrow] = useState<boolean>(false);
    const [previousPath, setPreviousPath] = useState<string>("/");
    const { site } = useStaticQuery(siteQuery);

    const [colorMode] = useColorMode();
    const fill = colorMode === "dark" ? "#fff" : "#000";
    const { basePath } = site.siteMetadata;

    // ==========================================================
    // Added Logic
    // ==========================================================
    const { location, navigate } = globalHistory;
    const intl = useIntl();
    const scrolled = useScrollPosition();
    const [
        { toggled, animated },
        { toggleMenu },
    ] = useMenuToggle();

    // For Inverted Boolean
    const inverted = toggleScroll ? !Boolean(scrolled) : false;

    // To Check whether a MenuItem is Active
    const isActive = (path) => location.pathname.includes(path);

    useEffect(() => {
        const { width } = getWindowDimensions();
        const tablet_ipad = getBreakpointFromTheme("tablet_ipad");

        const prev = localStorage.getItem("previousPath");
        const previousPathWasHomepage =
          prev === (basePath) || (prev && prev.includes("/page/"));
        const isNotPaginated = !location.pathname.includes("/page/");

        setShowBackArrow(
          previousPathWasHomepage && isNotPaginated && width <= tablet_ipad,
        );
        setPreviousPath(prev);
    }, []);

    return (
    <Header data-scroll-header fadeIn>
        <Nav inverted={ inverted }>
            <NavWrapper>
                <LogoContainer>
                    <LogoLink
                        to={basePath}
                        data-a11y="false"
                        title="Navigate back to the homepage"
                        aria-label="Navigate back to the homepage"
                        /*back={showBackArrow ? "true" : "false"}*/
                    >
                        {/*{showBackArrow && (
                        <BackArrowIconContainer>
                            <IconChevronLeft fill={fill} />
                        </BackArrowIconContainer>
                        )}*/}
                        <LogoHeader active={ toggled } inverted={ inverted }>DLMR <LogoHeaderSpan active={ toggled } inverted={ inverted }>Consulting</LogoHeaderSpan></LogoHeader>
                        <Hidden>Navigate back to the homepage</Hidden>
                    </LogoLink>
                </LogoContainer>
                <MiddleNavigation>
                    <Menu>
                        <MenuItem>
                            <MenuLink inverted={ inverted } to="/#services" data-scroll>{intl.formatMessage({ id: 'services-link' })}</MenuLink>
                        </MenuItem>

                        <MenuItem active={ isActive('web-development-services') }>
                            <MenuLink inverted={ inverted } to="/web-development-services/">{intl.formatMessage({ id: 'web-link' })}</MenuLink>
                        </MenuItem>

                        <MenuItem active={ isActive('online-marketing') }>
                            <MenuLink inverted={ inverted } to="/online-marketing/">{intl.formatMessage({ id: 'marketing-link' })}</MenuLink>
                        </MenuItem>

                        <MenuItem active={ isActive('seo-services') }>
                            <MenuLink inverted={ inverted } to="/seo-services/">{intl.formatMessage({ id: 'seo-link' })}</MenuLink>
                        </MenuItem>

                        {/*<MenuItem active={ isActive('app-development') }>
                            <MenuLink inverted={ inverted } to="/app-development/">{intl.formatMessage({ id: 'app-link' })}</MenuLink>
                        </MenuItem>*/}
                        
                        {/*<MenuItem active={ isActive('about') }>
                            <MenuLink inverted={ inverted } to="/about/">{intl.formatMessage({ id: 'about-link' })}</MenuLink>
                        </MenuItem>*/}
                        {/*<MenuItem className="blog-nav-link"><MenuLink to="/blog/">{intl.formatMessage({ id: 'blog-link' })}</MenuLink></MenuItem>*/}
                    </Menu>
                </MiddleNavigation>
                {/*<NavControls>
                {showBackArrow ? (
                    <button
                        onClick={() => navigate(previousPath)}
                        title="Navigate back to the homepage"
                        aria-label="Navigate back to the homepage"
                    >
                        <IconEx fill={fill} />
                    </button>
                ) : (
                    <>
                    <SharePageButton />
                    <DarkModeToggle inverted={ inverted } />
                    </>
                )}
                </NavControls>*/}
                <NavigationCTA>
                    <ContactLink to="/contact/" className={ inverted ? `button button--dark` : `button`} inverted={ inverted && `true` }>{intl.formatMessage({ id: 'contact' })}</ContactLink>
                </NavigationCTA>
                <HamburgerButton
                    onClick={ toggleMenu }
                    onKeyDown={ toggleMenu }
                    aria-label={ intl.formatMessage({ id: 'open-menu' }) }
                    active={ toggled }
                    role="navigation"
                >
                    <HamburgerIcon aria-hidden="true">
                        <HamburgerLine number={1} active={ toggled } inverted={ inverted } aria-hidden="true"></HamburgerLine>
                        <HamburgerLine number={2} active={ toggled } inverted={ inverted } aria-hidden="true"></HamburgerLine>
                        <HamburgerLine number={3} active={ toggled } inverted={ inverted } aria-hidden="true"></HamburgerLine>
                    </HamburgerIcon>
                </HamburgerButton>
            </NavWrapper>
        </Nav>
        <NavigationHeaderMobile toggled={toggled} animated={animated} toggleMenu={toggleMenu} />
    </Header>
  );
};

export default NavigationHeader;

// const BackArrowIconContainer = styled.div`
//     transition: 0.2s transform var(--ease-out-quad);
//     opacity: 0;
//     padding-right: 30px;
//     animation: FadeIn 0.3s linear forwards;

//     @keyframes FadeIn {
//         to {
//             opacity: 1;
//         }
//     }

//     ${mediaqueries.laptop`
//         display: none;
//     `};
// `;

// const NavContainer = styled.div`
//     position: relative;
//     z-index: 100;
//     padding-top: 100px;
//     display: flex;
//     justify-content: space-between;

//     ${mediaqueries.laptop`
//         padding-top: 50px;
//     `};;

//     @media screen and (max-height: 800px) {
//         padding-top: 50px;
//     }
// `;

const LogoLink = styled(Link)<{ back: string }>`
    position: relative;
    display: flex;
    align-items: center;
    left: ${p => (p.back === "true" ? "-54px" : 0)};

    ${mediaqueries.laptop`
        left: 0
    `};

    &[data-a11y="true"]:focus::after {
        content: "";
        position: absolute;
        left: -10%;
        top: -30%;
        width: 120%;
        height: 160%;
        border: 2px solid ${p => p.theme.colors.accent};
        background: rgba(255, 255, 255, 0.01);
        border-radius: 5px;
    }

    // &:hover {
    //     ${BackArrowIconContainer} {
    //         transform: translateX(-3px);
    //     }
    // }
`;

const NavControls = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    // ==========================================================
    // Added Code
    // ==========================================================
    margin-left: auto;
    margin-right: 30px;
    z-index: 14;

    ${mediaqueries.tablet`
        right: -5px;
    `};
`;

// const ToolTip = styled.div<{ isDark: boolean; hasCopied: boolean }>`
//     position: absolute;
//     padding: 4px 13px;
//     background: ${p => (p.isDark ? "#000" : "rgba(0,0,0,0.1)")};
//     color: ${p => (p.isDark ? "#fff" : "#000")};
//     border-radius: 5px;
//     font-size: 14px;
//     top: -35px;
//     opacity: ${p => (p.hasCopied ? 1 : 0)};
//     transform: ${p => (p.hasCopied ? "translateY(-3px)" : "none")};
//     transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

//     &::after {
//         content: "";
//         position: absolute;
//         left: 0;
//         right: 0;
//         bottom: -6px;
//         margin: 0 auto;
//         width: 0;
//         height: 0;
//         border-left: 6px solid transparent;
//         border-right: 6px solid transparent;
//         border-top: 6px solid ${p => (p.isDark ? "#000" : "rgba(0,0,0,0.1)")};
//     }
// `;

const IconWrapper = styled.button<{ isDark: boolean }>`
    opacity: 0.5;
    position: relative;
    border-radius: 5px;
    width: 40px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    margin-left: 30px;

    &:hover {
        opacity: 1;
    }

    &[data-a11y="true"]:focus::after {
        content: "";
        position: absolute;
        left: 0;
        top: -30%;
        width: 100%;
        height: 160%;
        border: 2px solid ${p => p.theme.colors.accent};
        background: rgba(255, 255, 255, 0.01);
        border-radius: 5px;
    }

    ${mediaqueries.tablet_ipad`
        display: inline-flex;
        transform: scale(0.708);
        margin-left: 10px;


        &:hover {
            opacity: 0.5;
        }
    `};
`;

// This is based off a codepen! Much appreciated to: https://codepen.io/aaroniker/pen/KGpXZo
const MoonOrSun = styled.div<{ isDark: boolean }>`
    margin: unset;
    padding: unset;
    margin-left: unset;
    margin-right: unset;
    left: unset;
    right: unset;
    display: initial;

    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: ${p => (p.isDark ? "4px" : "2px")} solid
        ${p => p.theme.colors.primary};
    background: ${p => p.theme.colors.primary};
    transform: scale(${p => (p.isDark ? 0.55 : 1)});
    transition: all 0.45s ease;
    overflow: ${p => (p.isDark ? "visible" : "hidden")};

    &::before {
        content: "";
        position: absolute;
        right: -9px;
        top: -9px;
        height: 24px;
        width: 24px;
        border: 2px solid ${p => p.theme.colors.primary};
        border-radius: 50%;
        transform: translate(${p => (p.isDark ? "14px, -14px" : "0, 0")});
        opacity: ${p => (p.isDark ? 0 : 1)};
        transition: transform 0.45s ease;
    }

    &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: -4px 0 0 -4px;
        position: absolute;
        top: 50%;
        left: 50%;
        box-shadow: 0 -23px 0 ${p => p.theme.colors.primary},
            0 23px 0 ${p => p.theme.colors.primary},
            23px 0 0 ${p => p.theme.colors.primary},
            -23px 0 0 ${p => p.theme.colors.primary},
            15px 15px 0 ${p => p.theme.colors.primary},
            -15px 15px 0 ${p => p.theme.colors.primary},
            15px -15px 0 ${p => p.theme.colors.primary},
            -15px -15px 0 ${p => p.theme.colors.primary};
        transform: scale(${p => (p.isDark ? 1 : 0)});
        transition: all 0.35s ease;

        ${p => mediaqueries.tablet_ipad`
            transform: scale(${p.isDark ? 0.92 : 0});
        `};
    }
`;

const MoonMask = styled.div<{ isDark: boolean, inverted?: boolean }>`
    margin: unset;
    padding: unset;
    margin-left: unset;
    margin-right: unset;
    left: unset;
    display: initial;

    position: absolute;
    right: -1px;
    top: -8px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 0;
    background: ${p => p.theme.colors.background};
    transform: translate(${p => (p.isDark ? "14px, -14px" : "0, 0")});
    opacity: ${p => (p.isDark ? 0 : 1)};
    transition: ${p => p.theme.colorModeTransition}, transform 0.45s ease;

    ${p => p.inverted && `
        background: ${p.theme.colors.brand};
        background: #10a7ff;
        background: #0da4ff;
    `};
`;

const Hidden = styled.span`
    position: absolute;
    display: inline-block;
    opacity: 0;
    width: 0px;
    height: 0px;
    visibility: hidden;
    overflow: hidden;
`;

// =============================================================================================================================
// =============================================================================================================================
// New Code
// =============================================================================================================================
// =============================================================================================================================
const Header = styled.header<{ fadeIn?: boolean }>`
    z-index: 10;
    -webkit-animation-name: ${FadeIn};
    animation-name: ${FadeIn};

    ${p => p.fadeIn && `
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

    `};
`;

const Nav = styled.nav<{ inverted?: boolean }>`
    position: fixed;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    background-color: rgba(255, 255, 255, .99);
    -webkit-backdrop-filter: blur(10px);
    z-index: 50;
    transition: 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);

    ${p => p.inverted && `
        border-bottom: none;
        background-color: transparent;
    `};
`;

const NavWrapper = styled.div`
    padding: 0 4%;
    height: 60px;
    padding-left: calc(4% - 10px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    ${mediaqueries.from_laptop`
        padding: 0;
        max-width: 900px;
    `};

    ${mediaqueries.from_desktop`
        max-width: 1100px;
    `};
`;

const LogoContainer = styled.div`
    margin: 0;
    transition: .3s;
    z-index: 12;

    &:hover {
        opacity: .8;
    }

    ${mediaqueries.from_laptop`
        margin-left: -10px;
    `};

    a {
        margin-bottom: 0;
    }
`;

const LogoHeader = styled(Headings.h2)<{ active?: boolean, inverted?: boolean }>`
    font-weight: ${p => p.theme.fontWeights.extrabold};
    line-height: 1;
    margin: 0;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    color: black;

    ${p => p.inverted && !p.active ? `
        color: white;
    ` : ``};
`;

const LogoHeaderSpan = styled.span<{ active?: boolean, inverted?: boolean }>`
    font-size: 16px;
    display: inline-block;
    color: ${p => p.theme.colors.brand};
    font-weight: ${p => p.theme.fontWeights.bold};


    ${p => p.inverted && !p.active ? `
        color: white;
        // // Place an image as the background clip
        // background: transparent;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // -webkit-text-fill-color: white;
    ` : ``};
`;

const MiddleNavigation = styled.div`
    display: none;
    margin: 0;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 11;
    
    // was tablet_ipad
    ${mediaqueries.from_tablet_ipad`
        // display: block;
        // ==========================================================
        // CHANGE OF ADRIANO
        // ==========================================================
        display: flex;
        justify-content: flex-end;
        flex-direction: row !important;
        // ==========================================================
        // END OF CHANGE
        // ==========================================================
    `};
`;

const Menu = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: unset;
    margin-left: auto;
    justify-self: flex-end;
    // margin-right: 200px;
    margin-right: 130px;

    ${mediaqueries.from_laptop`
        // margin-right: 180px;
        margin-right: 118px;
    `};
`;

const MenuItem = styled.li<{ active?: boolean }>`
    margin-left: 25px;
    margin-right: 25px;
    display: block;
    transition: .3s;

    margin: 0 10px;

    &:last-of-type {
        margin-right: 30px
    }

    ${mediaqueries.from_desktop`
        margin-left: 40px;
        margin-right: 40px;
        margin: 0px 15px;

        &:hover a {
            opacity: .6;
        }
    `};

    ${p => p.active && `
        ${MenuLink} {
            font-weight: ${p.theme.fontWeights.bold};
        }
    `};
`;

const MenuLink = styled(Link)<{ inverted?: boolean }>`
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    display: block;
    width: auto;
    height: auto;
    transition: .3s;

    ${p => p.inverted ? `
        color: white;
    ` : ``};
`;

const NavigationCTA = styled.div`
    margin: 0;
    z-index: 12;
    display: none;

    // was tablet_ipad
    ${mediaqueries.from_tablet_ipad`
        display: block;
    `};
`;

const ContactLink = styled(Link)<{ inverted?: boolean }>`
    padding-top: 5px;
    padding-bottom: 5px;
    // padding-left: 16px;
    // padding-right: 16px;
    font-size: 14px;

    padding-left: 26px;
    padding-right: 26px;
    transition: .3s ease;

    // ${p => p.inverted && `
    //     background: ${p.theme.colors.primary};
    // `};
`;

// ==========================================================
// Hamburger Button Component
// ==========================================================
const HamburgerButton = styled(Button)<{ active?: boolean }>`
    width: 40px;
    height: 40px;
    margin: 0;
    margin-right: -10px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: none;
    
    // was tablet_ipad
    ${mediaqueries.from_tablet_ipad`
        display: none;
        visibility: hidden;
        opacity: 0;
    `};

    ${p => p.active && `
        transform: rotate(90deg);
    `};
`;

const HamburgerIcon = styled.div`
        position: absolute;
        top: 13px;
`;

const HamburgerLine = styled.span<{ number?: number, active?: boolean, inverted?: boolean }>`
    width: 20px;
    height: 2px;
    background-color: black;
    border-radius: 10px;
    transition: 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: none;
    position: absolute;

    ${p => p.inverted ? `
        background-color: white;

        ${p.active ? `
            background-color: #000;
        ` : ``};
    ` : ``};

    ${p => p.active ? `
        ${p.number === 1 ? `
            transform: rotate(45deg);
            margin-top: 6px;
        ` : ``};

        ${p.number === 2 ? `
            width: 0;
        ` : ``};

        ${p.number === 3 ? `
            transform: rotate(-45deg);
            margin-top: 6px;
        ` : ``};
    ` : `
        ${p.number === 2 ? ` margin-top: 6px; ` : ``};
        ${p.number === 3 ? ` margin-top: 12px; ` : ``};
    `};
`;
