import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const CardImage = styled.div<{ addBackgroundGradient?: boolean, brightnessFilter?: boolean, flexible?: boolean }>`
    width: 100%;
    margin: 0 auto;
    display: block;
    height: 150px;
    overflow: hidden;
    border-radius: .25em .25em 0 0;

    // padding: 0 4rem;
    // transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

    & img, & svg {
        transition: transform 1s cubic-bezier(0.1,0.2,.7,1) !important;
        border-radius: 0px;
    }

    & svg {
        max-width: 100%;
        max-height: 100px;
        align-self: center
    }

    // Had to brake up "if flexible" if statements in to separate media queries
    // From tablet Media
    ${p =>
        p.flexible
            ? ``
            : mediaqueries.from_tablet`
                    height: 200px;
            `};
    // From tablet_ipad Media
    ${p =>
        p.flexible
            ? ``
            : mediaqueries.from_tablet_ipad`
                height: 250px;
            `};
    // From laptop Media
    ${p =>
        p.flexible
            ? ``
            : mediaqueries.from_laptop`
                height: 300px;
            `};

    ${p => p.addBackgroundGradient && `
        &:before, &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 85%;
            display: block;
            z-index: 1;
            content: '';
            background: linear-gradient(to bottom, rgba(15,15,15,.0), rgba(15,15,15,.7) 100%);
            -webkit-transition: opacity .65s cubic-bezier(0.2, 0.8, 0.2, 1);
            transition: opacity .65s cubic-bezier(0.2, 0.8, 0.2, 1);
        }

        &:after {
            opacity: 0;

            background: linear-gradient(to bottom, rgba(73, 240, 230, .5), rgb(44, 107, 246) 100%);
            background: linear-gradient(to bottom, rgba(73, 240, 230, .0), rgb(44, 107, 246) 100%);
        }

    `};

    ${p => p.brightnessFilter && `
        & img {
            filter: brightness(0.85);
        }
    `};
`;

export default CardImage;
