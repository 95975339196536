import styled from "@emotion/styled";
// import mediaqueries from "@styles/media";

const Button = styled.button`
  // color: ${p => p.theme.colors.articleText};
  // font-family: ${p => p.theme.fonts.sansSerif};
  // transition: ${p => p.theme.colorModeTransition};
  cursor: pointer;
    
`;

export default Button;
