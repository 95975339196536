if (typeof window !== "undefined") {
    // // eslint-disable-next-line global-require
    // var SmoothScroll = require("smooth-scroll");
    
    // // eslint-disable-next-line no-use-before-define
    // const scroll = new SmoothScroll('a[href*="#"][data-scroll]', {
    //     // speedAsDuration: true,
    //     // selector: '[data-scroll]',
    //     header: '[data-scroll-header]',
    //     ignore: '[data-scroll-ignore]',
    //     speed: 500,
    //     easing: 'easeInOutCubic',
    //     offset: 0,
    // });

    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"][data-scroll]');
}