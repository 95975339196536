import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const GridBase = styled.div`
    width: 100%;
    margin: 0 auto;
    display: grid;
    justify-items: stretch;
    
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    ${mediaqueries.tablet_ipad`
        max-width: 550px;
        padding: 0 3%;
    `};
`;

export default GridBase;
