/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import SchemaOrg from '@components/SEO/SchemaOrg.Modified';
import { getURL } from '@utils';

const SEO: React.FC<{}> = ({ description, lang, meta, title, slug, noIndex }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author
            organization {
              name
              url
              logo
            }
          }
        }
      }
    `
  )

  const intl = useIntl();
  const defaultLang = intl.formatMessage({ id: 'default' });
  const metaDescription = description || site.siteMetadata.description;
  lang = lang === 'current' ? intl.locale : lang;
  const finalURL = site.siteMetadata.siteUrl + getURL(lang, defaultLang, slug);

  // console.log('lang:', lang, 'finalURL:', finalURL, 'defaultLang:', defaultLang, 'intl.locale:', intl.locale);

  const noIndexMeta = noIndex ? [{name: 'robots', content: 'noindex'}] : [];

  const tags = noIndex ? [] : [
        // ==========================================================
        // Facebook Tags
        // ==========================================================
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:locale`,
          content: lang,
        },
        {
          property: `og:url`,
          content: finalURL,
        },
        {
          property: `og:image`,
          content: site.siteMetadata.siteUrl + `/webpage.jpg`, // Change later
        },
        // ==========================================================
        // Twitter Tags
        // ==========================================================
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: finalURL,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: site.siteMetadata.siteUrl + `/webpage.jpg`, // Change later
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: `twitter:site`,
        //   content: `@` + site.siteMetadata.author, // Change later
        // },
  ];

  return (
    <React.Fragment>
    <IntlContextConsumer>
    {({ languages, language: currentLocale }) => 
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={tags.concat([
        // ==========================================================
        // Basic Tags
        // ==========================================================
        {
          name: `description`,
          content: metaDescription,
        },
        {
          itemprop: `name`,
          content: title,
        },
        {
          itemprop: `image`,
          content: site.siteMetadata.siteUrl + `/webpage.jpg`, // Change later
        },
        {
          itemprop: `description`,
          content: metaDescription,
        },
      ]).concat(meta).concat(noIndexMeta)}
    >
      {!noIndex && languages.map(language => {
        // ==========================================================
        // TODO: CLEANUP HERE
        // ==========================================================
        if (language !== lang && language !== defaultLang) {
          return (
            <link rel="alternate" href={`${site.siteMetadata.siteUrl}${getURL(language, defaultLang, slug)}`} hrefLang={language} key={language}/>
          );
        }
        if (language === lang) {
          const href = `${site.siteMetadata.siteUrl}${getURL(defaultLang, defaultLang, slug)}`;
          return(
            <link rel="canonical" href={href} hrefLang={defaultLang} key={href}/>
          );
        }
      }
    )}
    </Helmet>
    }
    </IntlContextConsumer>
    { noIndex ? '' :
    <SchemaOrg
      url={finalURL}
      siteUrl={site.siteMetadata.siteUrl}
      title={`${title} | ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
    />
    }
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `hu`,
  meta: [],
  description: ``,
  slug: ``,
  noIndex: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
  noIndex: PropTypes.bool,
}

export default SEO;
