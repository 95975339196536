import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

import ISection from "@types";

const Section = styled.section<{ISection}>`
    padding-top: 70px;
    padding-bottom: 70px;
    clear: both;
    transition: ${p => p.theme.colorModeTransition};

    ${mediaqueries.from_tablet_ipad`
        padding-top: 90px;
        padding-bottom: 90px;
    `};

    ${mediaqueries.from_laptop`
        padding-top: 110px;
        padding-bottom: 110px;
    `};

    ${mediaqueries.from_desktop`
        padding-top: 120px;
        padding-bottom: 120px;
    `};
    
    // ==========================================================
    // Dark Section
    // ==========================================================
    ${p => p.dark && `
        background-color: black;
        background-color: ${p.theme.colors.brandDark};

        & a {
            // color: ${p.theme.colors.brandLight};
        }

        & .button {
            color: white;
            // background-color: ${p.theme.colors.brandLight};

            &:hover {
                // background-color: #4fa9fb;
            }
        }

        & *, h1, h2, h3, h4, h5, h6, p {
            color: white;
        }

        &:after {
            background-color: ${p.theme.colors.brandDark};
        }
    `};

    // ==========================================================
    // Small Section
    // ==========================================================
    ${p => p.small && `
        padding-top: 40px;
        padding-bottom: 40px;
    `};

    ${p => p.small && mediaqueries.from_tablet_ipad`
        padding-top: 40px;
        padding-bottom: 40px;
    `};

    ${p => p.small && mediaqueries.from_laptop`
        padding-top: 60px;
        padding-bottom: 60px;
    `};

    ${p => p.small && mediaqueries.from_desktop`
        padding-top: 80px;
        padding-bottom: 80px;
    `};

    // ==========================================================
    // With After Content Section
    // ==========================================================
    ${p => p.withContentAfter && `
        padding-bottom: 0px !important;

        &:after {
            z-index: -1;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 150px;
            background: #fff;
        }
    `};

    ${p => p.withContentAfter && mediaqueries.from_laptop`
        &:after {
            height: 300px
        }
    `};

    // ==========================================================
    // Hero Section
    // ==========================================================
    ${ p => p.hero && mediaqueries.from_mobile_i6`
        padding-top: 110px;
    `};

    ${p => p.hero && mediaqueries.from_tablet_ipad`
        padding-top: 140px;
    `};

    ${p => p.hero && mediaqueries.from_desktop`
        padding-top: 150px;
    `};

    ${p => p.fullHero && `
        height: 100vh;
    `}

    // ==========================================================
    // Narrow Section
    // ==========================================================
    ${p => p.narrow && mediaqueries.tablet_ipad`
        max-width: 550px;
    `};
        
    ${p => p.narrow && mediaqueries.from_tablet_ipad`
        max-width: 600px;
    `};

    // ==========================================================
    // Gradient Background Section
    // ==========================================================
    ${p => p.gradientBg && `
        overflow: hidden;
        background: ${p.theme.colors.gradientBg};
        color: white;

        & p, & h1, & h2, & h3, & h4 {
            color: white
        }
    `};

    // ==========================================================
    // No Paddings
    // ==========================================================
    ${p => p.noPaddingTop && `
        padding-top: 0px !important;
    `};

    ${p => p.noPaddingBottom && `
        padding-bottom: 0px !important;
    `};
`;

export default Section;
