import { useEffect, useState } from 'react';

import { scrollable } from '@utils';

export const useMenuToggle = () => {
    const [ toggled, setToggled ] = useState<boolean>(false);
    const [ animated, setAnimated ] = useState<boolean>(false);

    /*value: boolean = true*/
    const toggleMenu = () => {
        setToggled(!toggled);
        const scroll = toggled ? 'disable' : 'enable';
        scrollable(scroll);

        setTimeout(function() {
            setAnimated(!animated);
        }, 1e3);
    };


    return [
        { toggled, animated },
        { toggleMenu }
    ];
};