// import { Link } from "gatsby";
import { Link } from "gatsby-plugin-intl";

import styled from "@emotion/styled";
// import mediaqueries from "@styles/media";

const AnchorLink = styled(Link)<{ button?: boolean, white?: boolean, dark?: boolean }>`
  // color: ${p => p.theme.colors.articleText};
  // font-family: ${p => p.theme.fonts.sansSerif};
  transition: ${p => p.theme.colorModeTransition};
  cursor: pointer;
    
`;

export default AnchorLink;
