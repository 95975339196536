import React from 'react';
import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from "@components/Headings";
import Paragraph from "@components/Paragraph";
import Anchor from '@components/Anchor';
import Link from '@components/Link';
import LinkContainer from '@components/Link/Link.Container';
import SocialLinks from '@components/SocialLinks/SocialLinks.Basic';
import LanguageLinks from '@components/LanguageLinks';

import SectionTemplate from '@types';


const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            name
            social {
              url
              name
            }
          }
        }
      }
    }
  }
`;

const Footer: SectionTemplate = ({ sectionContext }) => {
    const intl = useIntl();
    const results = useStaticQuery(siteQuery);
    const { name, social } = results.allSite.edges[0].node.siteMetadata;

    const copyrightDate = new Date().getFullYear();

    return (
        <footer>
            <Section dark small {...sectionContext}>
            <Wrapper narrow>
                <SocialLinksContainer>
                    <Headings.h2 center>DLMR Consulting</Headings.h2>
                    <Paragraph center>{intl.formatMessage({ id: 'footer-subtitle' })}</Paragraph>
                    <StyledLinkContainer>
                        <Anchor href="mailto:hello@dlmrconsulting.com" className="button button--dark">{intl.formatMessage({ id: 'email-us' })}</Anchor>
                        <Link to="/contact/" className="button button--white">{intl.formatMessage({ id: 'landing-get-in-touch' })}</Link>
                    </StyledLinkContainer>
                    <LanguageLinksContainer>
                        <LanguageLinks />
                    </LanguageLinksContainer>
                </SocialLinksContainer>
                <FooterContainer>
                    <ListContainer>
                        <Headings.h3>{intl.formatMessage({ id: 'services-title' })}</Headings.h3>
                        <ul>
                            <ListItem><FooterLink to="/web-development-services/">{intl.formatMessage({ id: 'services-web' })}</FooterLink></ListItem>
                            <ListItem><FooterLink to="/online-marketing/">{intl.formatMessage({ id: 'services-marketing' })}</FooterLink></ListItem>
                            <ListItem><FooterLink to="/seo-services/">{intl.formatMessage({ id: 'services-seo' })}</FooterLink></ListItem>
                            {/*<ListItem><FooterLink to="/ui-ux-design/">{intl.formatMessage({ id: 'services-ui' })}</FooterLink></ListItem>*/}
                            <ListItem><FooterLink to="/app-development/">{intl.formatMessage({ id: 'services-app' })}</FooterLink></ListItem>
                        </ul>
                    </ListContainer>
                    <ListContainer>
                        <Headings.h3>{intl.formatMessage({ id: 'about' })}</Headings.h3>
                        <ul>
                            {/*<ListItem><FooterLink to="/about/">{intl.formatMessage({ id: 'about' })}</FooterLink></ListItem>*/}
                            <ListItem><FooterLink to="/terms-and-conditions/">{intl.formatMessage({ id: 'terms' })}</FooterLink></ListItem>
                            <ListItem><FooterLink to="/privacy-policy/">{intl.formatMessage({ id: 'privacy' })}</FooterLink></ListItem>
                        </ul>
                    </ListContainer>
                </FooterContainer>
            </Wrapper>
            </Section>
            <Section dark small noPaddingTop {...sectionContext}>
                <Wrapper>
                    <Hr />
                    <SocialLinksContainer>
                        <SocialLinks links={social} fill="#FFFFFF" />
                    </SocialLinksContainer>
                    <FooterContainer>
                        <FooterText>
                            © {copyrightDate} All rights reserved.&nbsp;–&nbsp;
                            <FooterAnchor
                                key="http://dlmrconsulting.com"
                                href="http://dlmrconsulting.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-a11y="false"
                                aria-label="Link to http://dlmrconsulting.com"
                            >Designed&nbsp;&amp;&nbsp;Coded by {name}</FooterAnchor>
                        </FooterText>
                    </FooterContainer>
                </Wrapper>
          </Section>
      </footer>
  );
};

export default Footer;


const SocialLinksContainer = styled.div`
    margin 30px auto;
    margin-bottom: 0px;
    text-align: center;
`;

const LanguageLinksContainer = styled.div`
    margin: 20px auto;
`;
const StyledLinkContainer = styled(LinkContainer)`
    ${mediaqueries.from_laptop`
        margin-bottom: 0px;
    `};
`;

const FooterContainer = styled.div`
    position: relative;
    // align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    // color: ${p => p.theme.colors.grey};
    display: flex;

    ${mediaqueries.tablet_ipad`
        display: block;
        flex-direction: column;
        padding-bottom: 40px;
    `};

    ${mediaqueries.tablet`
        padding-bottom: 30px;
    `};
`;

const Hr = styled.hr`
    background: white;
    opacity: 0.1;
`;

const FooterText = styled.p`
    font-size: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-bottom: none;
    padding-top: 15px;
    margin-bottom: 0;
    padding-bottom: 40px;
    transition: ${p => p.theme.transitionValue};
    color: #b5b5b5 !important;

    ${mediaqueries.from_mobile_i6`
        font-size: 10px;
    `};

    ${mediaqueries.from_tablet_ipad`
        margin-bottom: 0;
        padding-bottom: 30px;
        padding-top: 16px;
        font-size: 12px;
    `};
`;

const ListContainer = styled.div`
    margin-top: 20px;
`;

const ListItem = styled.li`
    padding: 5px 10px;
`;

const FooterAnchor = styled(Anchor)`
    color: ${p => p.theme.colors.white};
    opacity: .7;
    transition: ${p => p.theme.transitionValue};

    &:hover {
        opacity: 1;
        text-decoration: none;
    }
`;

const FooterLink = styled(Link)`
    font-size: 14px;
    opacity: 0.7;
    color: ${p => p.theme.colors.white};
    transition: ${p => p.theme.transitionValue};

    &:hover {
        opacity: 1;
        text-decoration: none;
    }
`;