import React from 'react';
import { IntlContextConsumer, useIntl, Link } from 'gatsby-plugin-intl';
// import { IntlContextConsumer, changeLocale, Link } from 'gatsby-plugin-intl';
import _ from 'lodash';
import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import { changeLocale } from '@utils';
import Button from '@components/Button';

const languageOptions = {
    hu: { key: 'hu', value: 'hu', flag: 'hu', text: 'Magyar' },
    // it: { key: 'it', value: 'it', flag: 'it', text: 'Italiano' },
    en: { key: 'en', value: 'en', flag: 'gb', text: 'English' },
};


const LanguageLinks: React.FC<{}> = () => {
    // HOTFIX FOR defaultLanguage to remove /base-locale/ from slug when redirecting
    const handleChange = (value, defaultLocale) => changeLocale(value, defaultLocale);
    const handleDefault = (e) => e.preventDefault();
    const intl = useIntl();

    return (
        <IntlContextConsumer>
        {
            ({ languages, language: currentLocale }) => {
                // All Languages
                const otherLanguages = _.clone(languageOptions);

                // Remove Current Language from otherLanguages object
                // delete otherLanguages[currentLocale];

                // Map other languages into render-ready form
                const options = Object.keys(otherLanguages).map(key => {
                    const option = languageOptions[key];
                    const className = 'flag-icon flag-icon-squared flag-icon-' + option.flag;
                    const Icon = <LanguageIcon className={className}></LanguageIcon>;
                    if (currentLocale === option.value) {
                        return (
                            <LanguageLink active onClick={handleDefault}>
                                {Icon}
                            </LanguageLink>
                        );
                    }  else {
                        return (
                            <LanguageLink onClick={() => handleChange(option.value, intl.formatMessage({ id: 'default'}))}>
                                {Icon}
                            </LanguageLink>
                        );
                    }
                });

                // Current Language
                // const currentLocaleClass = 'flag-icon flag-icon-squared flag-icon-' + languageOptions[currentLocale].flag; 
                // const CurrentLocaleIcon = <LanguageIcon className={currentLocaleClass}></LanguageIcon>;

                return (
                    <LanguagesContainer>
                    {options}
                    </LanguagesContainer>
                );
            }
        }
        </IntlContextConsumer>
    );
};

const LanguageIcon = styled.span`
    border-radius: 200%;
    width: 25px !important;
    height: 25px !important;
    display: block;
`;

const LanguageLink = styled.a`
    width: unset !important;
    height: unset !important;
    margin: unset !important;
    display: flex;
    cursor: pointer;
    padding: 10px;
    // filter: grayscale(50%);
    // opacity: 0.8;

    &:hover, &:active {
        // filter: none;
        // opacity: 1;
        border-radius: 0.25em;
        background: #002642;
    }

    ${p => p.active && `
        border-radius: 0.25em;
        background: #002642;
    `};

`;

const LanguagesContainer = styled.div`
    // margin: unset;
    // margin-right: 150px;
    // background: rgba(255,255,255,.95);
    padding: 10px;
    display: flex;
    border-radius: 0.25em;
    justify-content: center;
    
    ${mediaqueries.from_tablet_ipad`
        justify-content: center;
    `};

`;

export default LanguageLinks;