import React from 'react';
import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

interface IWaveSection {
    animated?: boolean;
    gradientBg?: boolean;
}

interface IWave {
    icon: React.ReactNode;
    waveContext?: IWaveSection;
} 

const Wave: React.FC<{ IWave }> = ({ icon, waveContext, ...props }) => {
    const Icon = (icon) ? icon : require('@misc/wave-2.svg');
    return (
    <WaveContainer { ...props }>
        <WaveSection {...waveContext}>
            <Icon />
        </WaveSection>
    </WaveContainer>
    );
};

const WaveContainer = styled.div<{ top?: boolean, bottom?: boolean }>`
    position: absolute;
    width: 100%;
    z-index: 1;

    ${p => p.top && `
        transform: matrix(1, 0, 0, -1, 0, 0);
        top: 0px;
    `}

    ${p => p.bottom && `
        transform: matrix(-1, 0, 0, 1, 0, 0);
        bottom: 0px;
    `}
`;

const WaveSection = styled.div<{ IWaveSection }>`
    position: relative;
    width: 100%;
    display: block;

    & svg {
        display: block;
        position: absolute;
        width: 100%;
        height: 4.5rem;

        ${mediaqueries.from_tablet_ipad`
           height: 5.5rem;
        `};

        & path {
            width: 100%;
            fill: url(#brandGradient);
            fill: ${p => p.theme.colors.gradientBg};
            ${p => p.animated && `
                animation: 25s linear 0s infinite alternate none running wave;
            `}
            ${p => p.gradientBg && `
                fill: white;
            `}
        }
    }
`;

export default Wave;