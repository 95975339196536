import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Grid = styled.div<{ columns?: number, rows?: number }>`
    width: 100%;
    margin: 0 auto;
    display: grid;
    justify-items: stretch;
    
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 40px;
    grid-row-gap: 40px;


    ${mediaqueries.tablet_ipad`
        max-width: 550px;
        padding: 0 3%;
    `};
    
    ${mediaqueries.from_tablet_ipad`
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 4%;
    `};
    
    // Columns
    ${ p => p.columns && mediaqueries.from_laptop`
        grid-template-columns: repeat(${p.columns}, 1fr);
    `};

    ${mediaqueries.from_desktop`
        grid-column-gap: 3%;
    `};

    // Rows
    ${p => p.rows && mediaqueries.from_tablet_ipad`
        grid-template-rows: repeat(${ p.rows }, 1fr);
    `};
`;

export default Grid;
