import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

import CSSFadeInProps from "@types";

/**
 * Usage:
 * @example
 * <Transitions.CSS.FadeInZoom as={condition ? Link : "div"} delay="0.2">
 *     ...stuff
 * </Transitions.CSS.FadeInZoom>
*/

const CSSFadeInZoom: React.FC<CSSFadeInProps> = ({ as, delay = '0.5', children, ...props }) => {
  return <Transition as={as} delay={delay} {...props}>{children}</Transition>;
};

export default CSSFadeInZoom;

export const FadeInZoom = keyframes`
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Transition = styled.div<{ delay?: number }>`
    opacity: 0;
    will-change: opacity, transform;
    animation-duration: 1s;
    animation-delay: ${p => p.delay ? `${p.delay}s` : `0s`};
    animation-name: ${FadeInZoom};
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

    // ==========================================================
    // For Chrome -- to have animation smoother
    // https://stackoverflow.com/questions/37629745/why-isnt-my-css3-animation-smooth-in-google-chrome-but-very-smooth-on-other-br
    // ==========================================================
    transform: translateZ(0);
    // backface-visibility: hidden;
    // perspective: 1000px;
`;