import React from "react";
import Helmet from "react-helmet";

export default React.memo(
  ({
    defaultTitle,
    title,
    url,
    siteUrl,
  }) => {
    const baseSchema = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        url: siteUrl,
        name: title,
        alternateName: defaultTitle,
      },
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        url,
        name: title,
        alternateName: defaultTitle,
      },
    ]

    const schema = baseSchema

    return (
      <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    )
  }
)