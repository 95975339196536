import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const CardContent = styled.div`
    width: 100%;
    margin: 0 auto;
    display: block;
    padding: 5% 2%;

    ${mediaqueries.from_tablet`
        padding: 7% 5%;
    `};

`;

export default CardContent;
