import React from "react";
import PropTypes from "prop-types";
import { ReactTypeformEmbed } from "react-typeform-embed";

import merge from 'lodash/merge';

/**
 * Make this Component into .ts Typescript file
 * use const Typeform: React.FC<{}> = ({}) => { }
 */

class Typeform extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.openForm = this.openForm.bind(this);
    }

    openForm() {
        this.typeformEmbed.typeform.open();
    }

    render() {
        return (
            <>
            <ReactTypeformEmbed
            url={ this.props.url }
            mode={ this.props.mode }
            popup={ this.props.mode === `popup` }
            autoOpen={false}
            opacity={70}
            { ...this.props }
            ref={tf => {
                this.typeformEmbed = tf;
            }}
            />
            <button onClick={this.openForm} style={merge({...this.props.styles, cursor: 'pointer'})} className={this.props.className}>
            { this.props.children }
            </button>
            </>
        )
    }
}

Typeform.propTypes = {
    url: PropTypes.string.isRequired,
    mode: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    styles: PropTypes.object
}
 
Typeform.defaultProps = {
    url: ``,
    mode: `popup`,
    className: `button button--dark`
}

export default Typeform