import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Wrapper = styled.div<{ zIndex?: number, narrow?: boolean }>`
    padding: 0 4%;

    ${mediaqueries.from_tablet_ipad`
        padding: 0 4%;
    `};

    ${mediaqueries.from_laptop`
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
    `};

    ${mediaqueries.from_desktop`
        max-width: 1100px;
    `};

    // ==========================================================
    // z-index
    // ==========================================================
    ${ p => p.zIndex && `z-index: ${p.zIndex};`};

    // ==========================================================
    // Narrow Section
    // ==========================================================
    ${p => p.narrow && mediaqueries.tablet_ipad`
        max-width: 550px;
    `};
        
    ${p => p.narrow && mediaqueries.from_tablet_ipad`
        max-width: 600px;
    `};
`;

export default Wrapper;
