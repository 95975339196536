import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const HeroWrapper = styled.div<{ narrow?: boolean, center?: boolean }>`

    // ==========================================================
    // Narrow
    // ==========================================================
    ${ p => p.narrow && `
        text-align: left;
        margin-bottom: 60px;
        max-width: 400px;
    `};
    
    ${ p => p.narrow && mediaqueries.from_tablet`
        max-width: 500px;
        margin-bottom: 80px;
    `};

    ${ p => p.narrow && mediaqueries.from_laptop`    
        text-align: left;
        margin-bottom: 0;
        margin-left: 0;
        max-width: 520px;
        margin-right: 0;
    `};
    
    ${ p => p.narrow && mediaqueries.from_desktop`
        width: 50%;
        // max-width: 600px;
    `};


    ${p => p.center ? mediaqueries.from_laptop`
        width: 100%;
        flex: none;
        margin: 0px auto;
        text-align: center;
        margin-bottom: 0;
        margin-left: 0;
        max-width: none;
        margin-right: 0;
    ` : `
        text-align: left;
    `};
`;

export default HeroWrapper;