import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const LinkContainer = styled.div`
    margin-top: 50px;
    
    & a:first-of-type {
        margin-right: 5px;

        ${mediaqueries.from_mobile_i6`
            margin-right: 20px;
        `};
        ${mediaqueries.from_tablet_ipad`
            margin-right: 5px;
        `};
        ${mediaqueries.from_laptop`
            margin-right: 20px;
        `};
    }

    ${mediaqueries.from_laptop`
        margin-bottom: 60px;
    `};
`;

export default LinkContainer;
