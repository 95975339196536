import styled from "@emotion/styled";
// import mediaqueries from "@styles/media";

const GridCustom = styled.div<{ columns?: string, rows?: string, columnGap?: number, rowGap?: number }>`
    width: 100%;
    margin: 0 auto;
    display: grid;
    justify-items: stretch;
    
    ${p => `
    grid-template-columns: ${p.columns};
    grid-template-rows: ${p.rows};
    grid-column-gap: ${p.columnGap};
    grid-row-gap: ${p.rowGap};
    `};
`;

export default GridCustom;
